import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { TextField } from '@mui/material';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import SEO from '../components/SEO';
import ContactForm from '../components/ContactForm';

function CopyrightComplaint({ siteConfig }) {
  const [request, setRequest] = useState({});
  const [requestedData, setRequestedData] = useState({});

  useEffect(() => {
    const selectedAuthorizationList = Object.keys(requestedData).filter(
      (key) => requestedData[key]
    );

    setRequest({
      ...request,
      ['Authorizations']:
        selectedAuthorizationList.length === 4
          ? selectedAuthorizationList.join(' | ')
          : ''
    });
  }, [requestedData]);

  useEffect(() => {
    console.log('request', request);
  }, [request]);

  return (
    <>
      <SEO
        title={'Online Procedure for Making Claims of Copyright Infringement'}
        description={
          'If you believe our website has copied your work in a way that constitutes infringement, please complete and submit the copyright complaint form on this page.'
        }
      />
      <ContactForm
        title="Online Procedure for Making Claims of Copyright Infringement"
        subject="Copyright Infringement Complaint"
        request={request}
        setRequest={setRequest}
        intro={
          <>
            <p>
              If you believe our website has copied your work in a way that
              constitutes infringement, please complete and submit the copyright
              complaint form on this page.
            </p>
            <p>
              Please be aware that submitting a report of intellectual property
              infringement is a serious matter with potential legal
              consequences. For example, intentionally submitting a misleading
              or fraudulent report may lead to liability for damages under 17
              U.S.C. § 512(f), or similar laws in other countries.
            </p>
            <p>
              Before submitting a notice of infringement, be sure to consider
              whether fair use or a similar exception to copyright or trademark
              law may apply to the use. If you are unsure whether the content
              you are considering reporting infringes your rights, you may wish
              to seek legal guidance before submitting a notice of infringement.
            </p>
            <p>
              Do not submit a notice of infringement unless you are the owner of
              the copyrighted material or trademark that you believe is being
              infringed, or an agent authorized to act on the owner's behalf.
            </p>
          </>
        }
        fields={
          <>
            <TextField
              variant="outlined"
              label="Company Name"
              fullWidth
              value={request['Company Name']}
              onChange={(event) =>
                setRequest({ ...request, ['Company Name']: event.target.value })
              }
            />

            <TextField
              variant="outlined"
              label="Title/Position"
              fullWidth
              value={request['Title/Position']}
              onChange={(event) =>
                setRequest({
                  ...request,
                  ['Title/Position']: event.target.value
                })
              }
            />

            <TextField
              variant="outlined"
              label="Street Address"
              required
              fullWidth
              value={request['Street Address']}
              onChange={(event) =>
                setRequest({
                  ...request,
                  ['Street Address']: event.target.value
                })
              }
            />

            <TextField
              variant="outlined"
              required
              label="City"
              fullWidth
              value={request['City']}
              onChange={(event) =>
                setRequest({ ...request, ['City']: event.target.value })
              }
            />

            <TextField
              variant="outlined"
              required
              label="State"
              fullWidth
              value={request['State']}
              onChange={(event) =>
                setRequest({ ...request, ['State']: event.target.value })
              }
            />

            <TextField
              variant="outlined"
              required
              label="Zip"
              fullWidth
              value={request['Zip']}
              onChange={(event) =>
                setRequest({ ...request, ['Zip']: event.target.value })
              }
            />

            <TextField
              variant="outlined"
              label="Phone"
              fullWidth
              value={request['Phone']}
              onChange={(event) =>
                setRequest({ ...request, ['Phone']: event.target.value })
              }
              required
            />

            <TextField
              variant="outlined"
              label="Fax"
              fullWidth
              value={request['Fax']}
              onChange={(event) =>
                setRequest({ ...request, ['Fax']: event.target.value })
              }
            />

            <TextField
              variant="outlined"
              label="Infringement Description"
              fullWidth
              value={request['Infringement Description']}
              multiline={true}
              required
              onChange={(event) =>
                setRequest({
                  ...request,
                  ['Infringement Description']: event.target.value
                })
              }
            />

            <FormGroup>
              <FormLabel component="legend">Select all that apply *:</FormLabel>
              <FormControlLabel
                control={<Checkbox />}
                label={
                  'I am the owner, or an agent authorized to act on behalf of the owner, of the copyright or an exclusive right under the copyright that is allegedly infringed.'
                }
                onChange={(event) =>
                  setRequestedData({
                    ...requestedData,
                    ['I am the owner, or an agent authorized to act on behalf of the owner, of the copyright or an exclusive right under the copyright that is allegedly infringed.']:
                      event.target.checked
                  })
                }
              />
              <FormControlLabel
                control={<Checkbox />}
                label={
                  'I have a good faith belief that the use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law; and'
                }
                onChange={(event) =>
                  setRequestedData({
                    ...requestedData,
                    ['I have a good faith belief that the use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law; and']:
                      event.target.checked
                  })
                }
              />
              <FormControlLabel
                control={<Checkbox />}
                label={'The information in this notification is accurate.'}
                onChange={(event) =>
                  setRequestedData({
                    ...requestedData,
                    ['The information in this notification is accurate.']:
                      event.target.checked
                  })
                }
              />
              <FormControlLabel
                control={<Checkbox />}
                label={
                  'I acknowledge that under Section 512(f) any person who knowingly materially misrepresents that material or activity is infringing may be subject to liability for damages.'
                }
                onChange={(event) =>
                  setRequestedData({
                    ...requestedData,
                    ['I acknowledge that under Section 512(f) any person who knowingly materially misrepresents that material or activity is infringing may be subject to liability for damages.']:
                      event.target.checked
                  })
                }
              />
            </FormGroup>
          </>
        }
        requiredFieldList={[
          'Street Address',
          'City',
          'State',
          'Zip',
          'Phone',
          'Infringement Description',
          'Authorizations'
        ]}
      />
    </>
  );
}

function mapStateToProps(state) {
  return {
    siteConfig: state.commonData.siteConfig
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {}
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CopyrightComplaint);
